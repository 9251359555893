import React, { Fragment, useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import axios from 'axios'

import Pagina from '../components/Pagina'
import './lgpd.css'
import RenderForm from 'gatsby-plugin-cloudofertas'
import Loading from '../components/Loader/loader'
const LGPD = () => {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/lgpd/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
      cloudOfertasForm(name: {eq: "CONTATO"}) {
        id
        formTypeField {
          name
          type
          required
          style
          length
          label
          formLov {
            formLovData {
              id
              value
              createdDate
            }
          }
        }
      }
    }
  `
  )

  const urlApi = `${process.env.GATSBY_CLOUDOFERTAS_API_URL}/sites/${process.env.GATSBY_CLOUDOFERTAS_SITE_ID}/form-data/${data.cloudOfertasForm.id}/`
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const config = {
        headers: {
          accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
        }
      }

      const result = await axios.post(urlApi, data, config)
      setLoading(false)
      setSubmitSuccess(true)

      setTimeout(() => {
        setSubmitSuccess(false)
      }, 6000)
      console.log('result:', result)
    } catch (err) {
      console.log('ERRO: ', err)
    }
  }

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <Loading loader={loading}/>
      <main>
        <div>
          <div id='img-main-lgpd' className="w-100"></div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 py-4'>
              <h1 className='titulo-lgpd'>POLÍTICA DE PRIVACIDADE E USO DE DADOS PESSOAIS</h1>
            </div>
            <div className='col-12 pb-3'>
              <p>Olá! Seja bem-vindo! Obrigado por utilizar a Rede Leste! </p>
            </div>
            <div className='col-12'>
              <p className='paragrafo1-lgpd'>
                Quando você utiliza a Rede Leste, você nos confia seus dados e suas informações, e nós nos comprometemos a manter esses dados protegidos por meio de sistema de proteção.<br />
                Nesse sentido, desenvolvemos a presente Política de Privacidade para explicar de maneira clara e acessível como as suas informações e seus dados serão coletados, usados, compartilhados e armazenados pelos nossos sistemas.<br />
                A nossa política está disponível em nosso website, nosso aplicativo e em nossas lojas físicas, assim, ao acessar e/ou utilizar o nosso website, nosso aplicativo e/ou nossos serviços diretamente em uma das lojas e nos passar seus dados manifestará sua concordância com os termos tratados no presente documento. Isso indica que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados.<br />
                Para melhor compreensão, dividimos a presente em políticas nos seguintes tópicos:
              </p>
            </div>
            <div className='col-12'>
              <ul className='lista1-lgpd'>
                <li>Quais as informações a Rede Leste coleta?</li>
                <li>Como a Rede Leste utiliza seus dados?</li>
                <li>Como se dá a exclusão dos seus dados;</li>
                <li>Compartilhamento de informações;</li>
                <li>Como tratamos a Segurança dos seus dados;</li>
                <li>Atualização da Política de Privacidade e Uso de Dados Pessoais;</li>
                <li>Lei aplicável.</li>
              </ul>
            </div>
            <div className='col-12'>
              <div>
                <div className='pt-3'>
                  <b>1- Quais informações a Rede Leste coleta?</b>
                  <p>Buscando sempre o melhor atendimento, bem como oferecer os serviços que disponibilizamos aos nossos clientes, tais quais serviços de entrega da mercadoria adquirida, e cumprir determinações legais, necessário se faz a coleta de alguns dados, que são:</p>
                  <ul className='topico2-lgpd'>
                    <li><b>Dados Pessoais:</b> Nome, data de nascimento, endereço, endereço de e-mail, CPF e número de telefone.</li>
                    <li><b>Dados Sensíveis:</b> Devido a nossa atividade fim ser do ramo farmacêutico/ drogarias, alguns dados relacionados a sua saúde, considerados sensíveis, serão coletados e compartilhados com os órgãos públicos (Ministério da Saúde e Anvisa). Ex.: Receita Médica.</li>
                  </ul>
                  <p>Esclarecemos que não nos responsabilizamos pela veracidade dos dados pessoais que nos foram fornecidos, sendo sua responsabilidade mantê-los atualizados ou corrigi-los diante de erro da informação repassada.<br /> Informamos ainda que utilizamos cookies de navegação sempre que você acessa nosso website, bem como, coletamos seus histórico de compras, mediante seu consentimento, para que possamos personalizar os descontos que iremos ofertar e/ou desenvolver nossas campanhas publicitárias. Assim, por meio desta política, você concorda expressamente com a coleta, o uso e o compartilhamento desses dados nos termos aqui estabelecidos.</p>
                </div>
                <div className='pt-3'>
                  <b>2- Como a Rede Leste utiliza seus dados?</b>
                  <p>Nós do Grupo Rede Leste, presamos muito pela sua privacidade, por isso, todos os dados e informações sobre você são tratados como confidencias e somente<br /> serão utilizadas essas informações para os fins aqui descritos e autorizados por você.<br /> Por meio desta política, a Rede Leste fica autorizado a utilizar seus dados para:</p>
                  <ul className='topico3-lgpd'>
                    <li>Permitir acesso a recursos e funcionalidades do ambiente da Rede Leste;</li>
                    <li>Emissão do Cupom Fiscal;</li>
                    <li>Comunicar sobre produtos, serviços, promoções, notícias ou outros assuntos que você possa ter interesse;</li>
                    <li>Personalizar serviços para que possam se adequar a seus gostos e interesses;</li>
                    <li>Realizar entrega dos produtos adquiridos, através dos serviços de delivery;</li>
                    <li>Melhorar sua experiência em nosso website, através da coleta de cookies, e /ou manter algumas informações a seu respeito salvas para que não<br /> tenha que nos dar a informação novamente.</li>
                  </ul>
                  <p>O eventual uso de seus dados para finalidades que não cumpram com essa prerrogativa, será feito mediante sua previa autorização.</p>
                </div>
                <div className='pt-3'>
                  <b>3- Como se dará exclusão dos seus dados?</b>
                  <p>Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar, por procedimento gratuito e facilitado, ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos do Grupo Rede Leste.</p>
                </div>
                <div className='pt-3'>
                  <b>4- Compartilhamento de informações</b>
                  <p>O Grupo Rede Leste pode compartilhar as informações que coleta com alguns terceiros ou parceiros abaixo elencados, tomando todas as medidas necessárias para que seus dados sejam enviados de maneira segura:</p>
                  <ul className='topico4-lgpd'>
                    <li>Operadora de Cartões e meios de pagamento;</li>
                    <li>Transportadora que realizará a entrega do produto adquirido; </li>
                    <li>Programa de Benefício em Medicamentos (caso aplicável);</li>
                    <li>Industria Farmacêutica;</li>
                    <li>Órgãos Públicos: Anvisa, Conselho Regional de Farmácia, Ministério da Saúde, Procon, Polícia Civil (mediante comprovação de inquérito policial), Secretaria da Fazenda do Estado de São Paulo, Tribunais de Justiça, dentre outros;</li>
                    <li>Prestador de Serviço contratado para armazenar os dados em nuvem;</li>
                    <li>Prestador de Serviço contrato para realizar a gestão, manutenção e/ou atualização do website da Rede Leste.</li>
                  </ul>
                  <p className='pt-2'>A Rede Leste se reserva o direito de fornecer dados e informações sobre você, incluindo interações suas, caso seja requisitado judicialmente para tanto, ato necessário para que a empresa esteja em conformidade com as leis nacionais, ou caso você autorize expressamente.
                    Asseguramos, ainda, que qualquer uso dos seus dados por terceiros estará de acordo com presente política. </p>
                </div>
                <div className='pt-3'>
                  <b>5- Da Segurança dos seus dados</b>
                  <p>O Grupo Rede Leste informa que os sistemas que utiliza para armazenamento dos seus dados são protegidos e controlados para garantir a segurança e são acessados somente por pessoas previamente autorizadas e possuidoras de login e senha.<br />
                    Todas as informações serão, sempre que possível, criptografas, além de que, utilizamos o sistema de FIREWAL ativo gerenciado pela Equipe interna de TI e possuímos antivírus monitorando os computadores e servidor em tempo real.<br />
                    A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas, requerer correção dos dados repassados, bloqueio ou eliminação dos dados (salvo os dados necessários para cumprir obrigação legal ou regulatória) e revogar o consentimento, mediante solicitação a ser encaminhada ao endereço eletrônicos dpo@rededrogaleste.com.br.<br />
                    Os dados serão mantidos armazenados somente até quando estes forem necessários ou relevantes para as finalidades descritas nessa política, ou em caso de períodos pré-determinados por lei, ou até quando esses dados forem necessários para manutenção de interesses legítimos do Grupo Rede Leste.<br />
                    Informamos ainda que não enviamos mensagens eletrônicas solicitando dados ou confirmações através de links e arquivos que possam ser executados em seu dispositivo, desta forma NUNCA envie seus dados através desses canais.<br />
                    Importante esclarecer que, mesmo diante de todos os meios de segurança tomados pelo Grupo Rede Leste, não podemos garantir completamente que todos os dados e informações sobre você em nossas plataformas estarão livres de acesso não autorizados, principalmente caso haja compartilhamento indevido de login e senha para acessas sua plataforma junto ao nosso website e/ou aplicativos. Portanto, você é o único responsável por manter sua senha de acesso em local seguro e se responsabiliza por eventual compartilhamento desta com terceiros.
                  </p>
                </div>
                <div className='pt-3'>
                  <b>6- Atualização da Política de Privacidade e Uso de Dados Pessoais</b>
                  <p>O Grupo Rede Leste se reserva o direito de alterar essa política sempre que entender necessário, visando fornecer à você mais segurança e conveniência. Sempre que houver atualizações, você será avisado por meios de nossos canais de comunicação e, caso sejam realizadas atualizações relevantes que ensejem novas autorizações suas, publicaremos uma nova política, sujeita a um novo consentimento seu.</p>
                </div>
                <div className='pt-3 pb-3'>
                  <b>7- Lei Aplicável e Foro</b>
                  <p>Este documento é regido e deverá ser interpretado de acordo com as legislações aplicáveis vigente.<br />
                    Fica eleito o foro da comarca de São Paulo/ SP, como competente para dirimir quaisquer questões oriundas do presente documento, com expressa renuncia a qualquer outro, por mais privilegiado que seja.<br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        <section className='theme--quaternary'>
          <div className='mb-3'>
            <div className='container pt-5 '>
              <div className='col-12 p-0'>
                <h2 className='theme-primary'>
                  FALE COM A GENTE!
                </h2>
                <span>Queremos escutar você, por esse canal você pode tirar suas dúvidas, fazer reclamações ou elogios. Atendimento em horário comercial, para preços e pedidos por gentileza entre em contato com uma de nossas lojas. Encontre o contato da <Link to='/lojas/'>loja mais próxima aqui</Link>.</span>
              </div>
            </div>
            <div>
              { data.cloudOfertasForm.formTypeField &&
                <RenderForm
                  formData={data.cloudOfertasForm}
                  onSubmit={onSubmit}
                  placeholder={true}
                  containerStyle='container'
                  rowStyle='row'
                  inputStyle='custom-form d-flex flex-column mt-3'
                  btnContainerStyle='custom-form col-12'
                  btnStyle='btn btn-block theme--secundary btn-outline-#1A3797 theme-border-2 text-white my-3'
                  btnName='ENVIAR'
                />
              }
              {submitSuccess &&
              <div className='container'>
                <div className='row'>
                  <div className='col'>
                    <div id="alertSucesso" className="alert alert-success" role="alert">
                      Obrigado por entrar em contato com a gente. Responderemos em até 48h.
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          </div>
        </section>
      </div>
      </main>
    </Fragment>
  )
}
export default LGPD
